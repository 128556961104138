import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

export function DashboardLayout({ isAdmin }) {
  const navigate = useNavigate();
  const [viewAsUser, setViewAsUser] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({
    tycoon: true,
    miniApps: true,
    megaPixel: true,
  });

  // Navigation items organized by category
  const navCategories = [
    {
      id: "megaPixel",
      label: "MegaPixel",
      icon: "💎",
      items: [{ path: "/dashboard/cashflow", label: "Cashflow" }],
    },
    {
      id: "tycoon",
      label: "Tycoon",
      items: [
        { path: "/dashboard/tycoon", label: "Tycoon Requires" },
        { path: "/dashboard/verified", label: "Verified Purchases" },
      ],
    },
    {
      id: "miniApps",
      label: "Apps",
      items: [
        { path: "/dashboard/metrics", label: "Metrics Chart" },
        { path: "/dashboard/calculator", label: "Earnings Calculator" },
        { path: "/dashboard/discover", label: "Discovery Helper" },
      ],
    },
  ];

  // Toggle category expansion
  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  // Filtered navigation items based on search
  const getFilteredNavItems = () => {
    if (!searchTerm) return navCategories;

    return navCategories
      .map((category) => ({
        ...category,
        items: category.items.filter((item) =>
          item.label.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      }))
      .filter((category) => category.items.length > 0);
  };

  // Check if screen is mobile
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth < 768) {
        setSidebarOpen(false);
      }
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Compute effective admin status based on toggle
  const effectiveAdminStatus = isAdmin && !viewAsUser;

  return (
    <div className="min-h-screen bg-[#09090B] flex">
      {/* Sidebar Navigation */}
      <aside
        className={`bg-[#18181B] border-r border-[#27272A] w-64 flex-shrink-0 transition-all duration-300 ease-in-out h-screen z-20 ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } ${isMobile || !sidebarOpen ? "fixed" : ""}`}
      >
        {/* Sidebar Header with Logo and Toggle */}
        <div className="p-4 border-b border-[#27272A] flex justify-between items-center">
          <span className="text-fortpixel-gold font-bold text-xl">
            FortPixel Studios
          </span>
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="text-gray-300 hover:text-white focus:outline-none"
          >
            {sidebarOpen ? "◀" : "▶"}
          </button>
        </div>

        {/* Search Box */}
        <div className="p-4 border-b border-[#27272A]">
          <div className="relative">
            <input
              type="text"
              placeholder="Search apps..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full bg-[#27272A] text-gray-300 rounded-md py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-fortpixel-gold"
            />
            <span className="absolute left-3 top-2.5 text-gray-400">🔍</span>
          </div>
        </div>

        {/* Navigation Links */}
        <nav className="p-2 overflow-y-auto max-h-[calc(100vh-10rem)]">
          {getFilteredNavItems().length > 0 ? (
            <ul className="space-y-2">
              {getFilteredNavItems().map((category) => (
                <li key={category.id} className="mb-2">
                  <div
                    className="flex items-center justify-between px-3 py-2 text-gray-300 hover:text-white cursor-pointer rounded-md hover:bg-[#27272A]"
                    onClick={() => toggleCategory(category.id)}
                  >
                    <div className="flex items-center">
                      <span className="mr-3">{category.icon}</span>
                      <span className="font-medium">{category.label}</span>
                    </div>
                    <span>{expandedCategories[category.id] ? "▼" : "▶"}</span>
                  </div>

                  {/* Category items */}
                  {expandedCategories[category.id] && (
                    <ul className="mt-1 ml-6 space-y-1">
                      {category.items.map((item) => (
                        <li key={item.path}>
                          <NavLink
                            to={item.path}
                            className={({ isActive }) =>
                              `flex items-center px-3 py-2 text-sm rounded-md transition-all duration-200 ease-in-out ${
                                isActive
                                  ? "bg-[#3F3F46] text-fortpixel-gold shadow-lg shadow-black/20"
                                  : "text-gray-300 hover:text-white hover:bg-[#27272A]"
                              }`
                            }
                          >
                            <span className="mr-3">{item.icon}</span>
                            {item.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center py-4 text-gray-400">
              No apps found matching "{searchTerm}"
            </div>
          )}
        </nav>
      </aside>

      {/* Main Content Area */}
      <div
        className={`flex-1 flex flex-col overflow-hidden ${
          sidebarOpen && !isMobile ? "" : "ml-0"
        } transition-all duration-300 ease-in-out w-full`}
      >
        {/* Top Navigation Bar */}
        <header className="bg-[#18181B] border-b border-[#27272A] py-2 px-4 z-10">
          <div className="max-w-7xl mx-auto flex justify-between items-center">
            {/* Logo in header when sidebar is closed */}
            {!sidebarOpen ? (
              <div className="flex items-center gap-3">
                <button
                  onClick={() => setSidebarOpen(true)}
                  className="text-gray-300 p-2 rounded-md hover:bg-[#27272A]"
                  aria-label="Open navigation"
                >
                  ☰
                </button>
                <span className="text-fortpixel-gold font-bold text-xl">
                  FortPixel Studios
                </span>
              </div>
            ) : (
              <div></div> /* Empty div to maintain flex layout when sidebar is open */
            )}
            {/* User Controls */}
            <div className="flex items-center gap-4">
              {isAdmin && (
                <button
                  onClick={() => setViewAsUser(!viewAsUser)}
                  className="flex items-center gap-2 bg-[#27272A] px-3 py-1.5 rounded-full shadow-lg shadow-black/20 hover:bg-[#3F3F46] transition-colors cursor-pointer"
                >
                  <div
                    className={`w-2 h-2 rounded-full ${
                      !viewAsUser ? "bg-fortpixel-gold" : "bg-gray-500"
                    }`}
                  ></div>
                  <span className="text-sm text-gray-300">
                    {!viewAsUser ? "Admin" : "Viewing as User"}
                  </span>
                </button>
              )}
              {!isAdmin && (
                <div className="flex items-center gap-2 bg-[#27272A] px-3 py-1.5 rounded-full shadow-lg shadow-black/20">
                  <div className="w-2 h-2 rounded-full bg-gray-500"></div>
                  <span className="text-sm text-gray-300">User</span>
                </div>
              )}
              <button
                onClick={handleLogout}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-md transition-colors duration-200 ease-in-out shadow-lg shadow-black/20"
              >
                Logout
              </button>
            </div>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex-1 overflow-auto p-6">
          <div className="max-w-7xl mx-auto">
            <Outlet context={{ isAdmin: effectiveAdminStatus }} />
          </div>
        </main>
      </div>

      {/* Overlay for mobile when sidebar is open */}
      {isMobile && sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
}
