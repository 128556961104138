import React, { useState } from "react";
import TimeSince from "./discover/TimeSince";
import Predict from "./discover/Predict";

const DiscoverHelper = () => {
  const [activeTab, setActiveTab] = useState("timeSince");

  return (
    <div className="w-full max-w-2xl mx-auto bg-gray-900 text-white rounded-lg shadow-lg">
      <div className="border-b border-gray-800">
        <nav className="flex">
          <button
            className={`px-4 py-2 text-sm font-medium ${
              activeTab === "timeSince"
                ? "border-b-2 border-fortpixel-gold text-fortpixel-gold"
                : "text-gray-400 hover:text-gray-300"
            }`}
            onClick={() => setActiveTab("timeSince")}
          >
            Time Since
          </button>
          <button
            className={`px-4 py-2 text-sm font-medium ${
              activeTab === "predict"
                ? "border-b-2 border-fortpixel-gold text-fortpixel-gold"
                : "text-gray-400 hover:text-gray-300"
            }`}
            onClick={() => setActiveTab("predict")}
          >
            Predict
          </button>
        </nav>
      </div>

      <div className="p-4">
        {activeTab === "timeSince" ? <TimeSince /> : <Predict />}
      </div>
    </div>
  );
};

export default DiscoverHelper;
