import React, { useState, useEffect, useMemo } from "react";
import {
  getDatabase,
  ref,
  push,
  remove,
  update,
  onValue,
  off,
} from "firebase/database";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CashflowManager = ({ isAdmin }) => {
  // State for active tab
  const [activeTab, setActiveTab] = useState("earnings");

  // State for earnings
  const [earnings, setEarnings] = useState([]);
  const [newEarning, setNewEarning] = useState({
    date: "",
    cad: "",
  });

  // State for expenses
  const [expenses, setExpenses] = useState([]);
  const [newExpense, setNewExpense] = useState({
    name: "",
    date: "",
    cad: "",
    usd: "",
    split: false,
  });

  // Shared state
  const [error, setError] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });

  // Modal states
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState({
    id: "",
    type: "", // "earning" or "expense"
    date: "",
    cad: "",
    name: "",
    split: false,
  });

  // Filter state
  const [showOnlyUnsplit, setShowOnlyUnsplit] = useState(false);

  // Chart state
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [availableYears, setAvailableYears] = useState([]);

  // Load earnings data with improved error handling and cleanup
  useEffect(() => {
    const db = getDatabase();
    const earningsRef = ref(db, "earnings");
    let isMounted = true;

    try {
      const unsubscribe = onValue(
        earningsRef,
        (snapshot) => {
          if (!isMounted) return;

          if (snapshot.exists()) {
            const earningsData = snapshot.val();
            const earningsArray = Object.entries(earningsData).map(
              ([id, data]) => ({
                id,
                ...data,
              })
            );
            setEarnings(earningsArray);
          } else {
            setEarnings([]);
          }
        },
        (error) => {
          console.error("Error loading earnings data:", error);
        }
      );

      return () => {
        isMounted = false;
        // Use both unsubscribe and off for complete cleanup
        unsubscribe();
        off(earningsRef);
      };
    } catch (error) {
      console.error("Error setting up earnings listener:", error);
      return () => {
        isMounted = false;
      };
    }
  }, []);

  // Load expenses data with improved error handling and cleanup
  useEffect(() => {
    const db = getDatabase();
    const expensesRef = ref(db, "expenses");
    let isMounted = true;

    try {
      const unsubscribe = onValue(
        expensesRef,
        (snapshot) => {
          if (!isMounted) return;

          if (snapshot.exists()) {
            const expensesData = snapshot.val();
            const expensesArray = Object.entries(expensesData).map(
              ([id, data]) => ({
                id,
                ...data,
              })
            );
            setExpenses(expensesArray);
          } else {
            setExpenses([]);
          }
        },
        (error) => {
          console.error("Error loading expenses data:", error);
        }
      );

      return () => {
        isMounted = false;
        // Use both unsubscribe and off for complete cleanup
        unsubscribe();
        off(expensesRef);
      };
    } catch (error) {
      console.error("Error setting up expenses listener:", error);
      return () => {
        isMounted = false;
      };
    }
  }, []);

  // Extract unique years from earnings and expenses for chart filtering
  useEffect(() => {
    const years = new Set();

    earnings.forEach((earning) => {
      const year = new Date(earning.date).getFullYear();
      years.add(year);
    });

    expenses.forEach((expense) => {
      const year = new Date(expense.date).getFullYear();
      years.add(year);
    });

    const sortedYears = Array.from(years).sort((a, b) => b - a);
    setAvailableYears(sortedYears);

    // Set default selected year to most recent if available
    if (sortedYears.length > 0 && !sortedYears.includes(selectedYear)) {
      setSelectedYear(sortedYears[0]);
    }
  }, [earnings, expenses, selectedYear]);

  // Fix the calculation bug in the monthlyData useMemo
  const monthlyData = useMemo(() => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Initialize data structure
    const data = {
      earnings: Array(12).fill(0),
      expenses: Array(12).fill(0),
      splitExpenses: Array(12).fill(0),
      cashflow: Array(12).fill(0),
    };

    // Process earnings
    earnings.forEach((earning) => {
      const date = new Date(earning.date);
      const year = date.getFullYear();
      const month = date.getMonth();

      if (year === selectedYear) {
        const amount = parseFloat(earning.cad) || 0;
        data.earnings[month] += amount;
        data.cashflow[month] += amount;
      }
    });

    // Process expenses
    expenses.forEach((expense) => {
      const date = new Date(expense.date);
      const year = date.getFullYear();
      const month = date.getMonth();

      if (year === selectedYear) {
        const amount = parseFloat(expense.cad) || 0;
        data.expenses[month] += amount;

        // For split expenses, only count half for cashflow and splitExpenses
        if (expense.split) {
          data.splitExpenses[month] += amount / 2;
          data.cashflow[month] -= amount / 2;
        } else {
          // For unsplit expenses, count half for splitExpenses but full for cashflow
          data.splitExpenses[month] += amount / 2;
          data.cashflow[month] -= amount / 2; // Only deduct half for cashflow
        }
      }
    });

    return {
      labels: months,
      earnings: data.earnings,
      expenses: data.expenses,
      splitExpenses: data.splitExpenses,
      cashflow: data.cashflow,
    };
  }, [earnings, expenses, selectedYear]);

  // Chart color schemes
  const chartColors = {
    earnings: {
      backgroundColor: "rgba(34, 197, 94, 0.2)",
      borderColor: "rgba(34, 197, 94, 1)",
    },
    expenses: {
      backgroundColor: "rgba(239, 68, 68, 0.2)",
      borderColor: "rgba(239, 68, 68, 1)",
    },
    splitExpenses: {
      backgroundColor: "rgba(249, 115, 22, 0.2)",
      borderColor: "rgba(249, 115, 22, 1)",
    },
    cashflow: {
      positive: {
        backgroundColor: "rgba(34, 197, 94, 0.2)",
        borderColor: "rgba(34, 197, 94, 1)",
      },
      negative: {
        backgroundColor: "rgba(239, 68, 68, 0.2)",
        borderColor: "rgba(239, 68, 68, 1)",
      },
    },
  };

  // Sort earnings
  const sortedEarnings = useMemo(() => {
    let sortedData = [...earnings];

    sortedData.sort((a, b) => {
      if (sortConfig.key === "date") {
        const dateA = new Date(a[sortConfig.key]);
        const dateB = new Date(b[sortConfig.key]);
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      }

      if (sortConfig.key === "cad") {
        const valueA = parseFloat(a[sortConfig.key]) || 0;
        const valueB = parseFloat(b[sortConfig.key]) || 0;
        return sortConfig.direction === "asc"
          ? valueA - valueB
          : valueB - valueA;
      }

      return 0;
    });

    return sortedData;
  }, [earnings, sortConfig]);

  // Sort expenses
  const sortedExpenses = useMemo(() => {
    let sortedData = [...expenses];

    sortedData.sort((a, b) => {
      if (sortConfig.key === "date") {
        const dateA = new Date(a[sortConfig.key]);
        const dateB = new Date(b[sortConfig.key]);
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      }

      if (sortConfig.key === "cad") {
        const valueA = parseFloat(a[sortConfig.key]) || 0;
        const valueB = parseFloat(b[sortConfig.key]) || 0;
        return sortConfig.direction === "asc"
          ? valueA - valueB
          : valueB - valueA;
      }

      if (sortConfig.key === "name") {
        return sortConfig.direction === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }

      return 0;
    });

    return sortedData;
  }, [expenses, sortConfig]);

  // Format date for display
  const formatDate = (dateString) => {
    // Create date with UTC time to avoid timezone issues
    const [year, month, day] = dateString.split("-");
    const formattedDate = new Date(Date.UTC(year, month - 1, day));

    return formattedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC", // Ensure timezone doesn't affect the date
    });
  };

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  // Render sort icon
  const renderSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    return sortConfig.direction === "asc" ? "↑" : "↓";
  };

  // Add earnings handling functions
  const handleAddEarning = async () => {
    if (!newEarning.date || !newEarning.cad) {
      setError("Date and CAD amount are required");
      return;
    }

    try {
      const db = getDatabase();
      const earningsRef = ref(db, "earnings");

      // Create earning object
      const earningData = {
        date: newEarning.date,
        cad: newEarning.cad,
      };

      await push(earningsRef, earningData);

      setNewEarning({ date: "", cad: "" });
      setError("");
    } catch (err) {
      setError("Failed to add earning: " + err.message);
    }
  };

  const handleRemoveEarning = async (id) => {
    try {
      const db = getDatabase();
      const earningRef = ref(db, `earnings/${id}`);
      await remove(earningRef);
    } catch (err) {
      setError("Failed to remove earning: " + err.message);
    }
  };

  const handleEditClick = (item, type) => {
    if (type === "earning") {
      setCurrentEditItem({
        id: item.id,
        type: "earning",
        date: item.date,
        cad: item.cad,
        name: "",
        split: false,
      });
    } else if (type === "expense") {
      setCurrentEditItem({
        id: item.id,
        type: "expense",
        date: item.date,
        cad: item.cad,
        name: item.name,
        split: item.split,
      });
    }
    setEditModalOpen(true);
  };

  const handleEditSave = async () => {
    const { id, type, date, cad, name, usd, split } = currentEditItem;

    if (!date || !cad) {
      setError("Date and CAD amount are required");
      return;
    }

    if (type === "expense" && !name) {
      setError("Name is required for expenses");
      return;
    }

    try {
      const db = getDatabase();

      if (type === "earning") {
        const earningRef = ref(db, `earnings/${id}`);
        await update(earningRef, { date, cad });
      } else if (type === "expense") {
        const expenseRef = ref(db, `expenses/${id}`);
        await update(expenseRef, {
          name,
          date,
          cad,
          usd: usd || "",
          split,
        });
      }

      setEditModalOpen(false);
      setError("");
    } catch (err) {
      setError(`Failed to update ${type}: ` + err.message);
    }
  };

  // Add expenses handling functions
  const handleAddExpense = async () => {
    if (!newExpense.name || !newExpense.date || !newExpense.cad) {
      setError("Name, Date, and CAD amount are required");
      return;
    }

    try {
      const db = getDatabase();
      const expensesRef = ref(db, "expenses");

      // Create expense object
      const expenseData = {
        name: newExpense.name,
        date: newExpense.date,
        cad: newExpense.cad,
        usd: newExpense.usd || "", // Include USD if provided
        split: newExpense.split,
      };

      await push(expensesRef, expenseData);

      setNewExpense({ name: "", date: "", cad: "", usd: "", split: false });
      setError("");
    } catch (err) {
      setError("Failed to add expense: " + err.message);
    }
  };

  const handleRemoveExpense = async (id) => {
    try {
      const db = getDatabase();
      const expenseRef = ref(db, `expenses/${id}`);
      await remove(expenseRef);
    } catch (err) {
      setError("Failed to remove expense: " + err.message);
    }
  };

  const handleToggleSplit = async (id, currentSplit) => {
    try {
      const db = getDatabase();
      const expenseRef = ref(db, `expenses/${id}`);
      await update(expenseRef, { split: !currentSplit });
    } catch (err) {
      setError("Failed to update split status: " + err.message);
    }
  };

  // Calculate split amount for expenses
  const calculateSplit = (amount) => {
    const value = parseFloat(amount);
    if (isNaN(value)) return "-";
    return (value / 2).toFixed(2);
  };

  // Calculate total unsplit expenses
  const totalUnsplitAmount = useMemo(() => {
    return expenses
      .filter((expense) => !expense.split)
      .reduce((total, expense) => total + (parseFloat(expense.cad) || 0), 0);
  }, [expenses]);

  return (
    <div className="space-y-6">
      {/* Edit Modal */}
      {editModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold text-white mb-4">
              Edit {currentEditItem.type === "earning" ? "Earning" : "Expense"}
            </h3>
            <div className="space-y-4">
              {currentEditItem.type === "expense" && (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Name
                    </label>
                    <input
                      type="text"
                      value={currentEditItem.name}
                      onChange={(e) =>
                        setCurrentEditItem({
                          ...currentEditItem,
                          name: e.target.value,
                        })
                      }
                      className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      USD (optional)
                    </label>
                    <input
                      type="number"
                      value={currentEditItem.usd || ""}
                      onChange={(e) =>
                        setCurrentEditItem({
                          ...currentEditItem,
                          usd: e.target.value,
                        })
                      }
                      className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                  </div>
                </>
              )}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Date
                </label>
                <input
                  type="date"
                  value={currentEditItem.date}
                  onChange={(e) =>
                    setCurrentEditItem({
                      ...currentEditItem,
                      date: e.target.value,
                    })
                  }
                  className="w-full p-2 rounded bg-gray-700 text-white"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  {currentEditItem.type === "earning"
                    ? "Payment Split (CAD)"
                    : "CAD Amount"}
                </label>
                <input
                  type="number"
                  value={currentEditItem.cad}
                  onChange={(e) =>
                    setCurrentEditItem({
                      ...currentEditItem,
                      cad: e.target.value,
                    })
                  }
                  className="w-full p-2 rounded bg-gray-700 text-white"
                />
              </div>
              {currentEditItem.type === "expense" && (
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={currentEditItem.split}
                    onChange={(e) =>
                      setCurrentEditItem({
                        ...currentEditItem,
                        split: e.target.checked,
                      })
                    }
                    className="h-4 w-4 text-blue-600 rounded bg-gray-700 border-gray-600"
                  />
                  <label className="ml-2 text-sm font-medium text-gray-300">
                    Split Paid
                  </label>
                </div>
              )}
              {error && <p className="text-red-500">{error}</p>}
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => {
                    setEditModalOpen(false);
                    setError("");
                  }}
                  className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  onClick={handleEditSave}
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main Tabs */}
      <div className="flex border-b border-gray-700">
        <button
          className={`py-2 px-4 font-medium ${
            activeTab === "earnings"
              ? "text-fortpixel-gold border-b-2 border-fortpixel-gold"
              : "text-gray-400 hover:text-gray-300"
          }`}
          onClick={() => setActiveTab("earnings")}
        >
          Earnings
        </button>
        <button
          className={`py-2 px-4 font-medium ${
            activeTab === "expenses"
              ? "text-fortpixel-gold border-b-2 border-fortpixel-gold"
              : "text-gray-400 hover:text-gray-300"
          }`}
          onClick={() => setActiveTab("expenses")}
        >
          Expenses
        </button>
        <button
          className={`py-2 px-4 font-medium ${
            activeTab === "analytics"
              ? "text-fortpixel-gold border-b-2 border-fortpixel-gold"
              : "text-gray-400 hover:text-gray-300"
          }`}
          onClick={() => setActiveTab("analytics")}
        >
          Analytics
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === "earnings" && (
        <div className="space-y-6">
          {/* Input Form - Only visible to admin */}
          {isAdmin && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="date"
                  value={newEarning.date}
                  onChange={(e) =>
                    setNewEarning({ ...newEarning, date: e.target.value })
                  }
                  className="p-2 rounded bg-gray-800 text-white"
                />
                <input
                  type="number"
                  value={newEarning.cad}
                  onChange={(e) =>
                    setNewEarning({ ...newEarning, cad: e.target.value })
                  }
                  placeholder="Payment Split (CAD)"
                  className="p-2 rounded bg-gray-800 text-white"
                />
              </div>
              <div className="flex justify-between items-center">
                <button
                  onClick={handleAddEarning}
                  className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                >
                  Add Earning
                </button>
                {error && <p className="text-red-500">{error}</p>}
              </div>
            </>
          )}

          {/* Earnings Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-gray-800">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("date")}
                  >
                    Date {renderSortIcon("date")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("cad")}
                  >
                    Payment Split (CAD) {renderSortIcon("cad")}
                  </th>
                  {isAdmin && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-gray-900 divide-y divide-gray-800">
                {sortedEarnings.length > 0 ? (
                  sortedEarnings.map((earning) => (
                    <tr key={earning.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {formatDate(earning.date)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        ${parseFloat(earning.cad).toFixed(2)}
                      </td>
                      {isAdmin && (
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <button
                            onClick={() => handleEditClick(earning, "earning")}
                            className="mr-2 px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white rounded"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleRemoveEarning(earning.id)}
                            className="px-3 py-1 bg-red-600 hover:bg-red-700 text-white rounded"
                          >
                            Remove
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={isAdmin ? 3 : 2}
                      className="px-6 py-4 text-center text-sm text-gray-400"
                    >
                      No earnings found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activeTab === "expenses" && (
        <div className="space-y-6">
          {/* Filter and Total Section */}
          <div className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center space-x-4">
              <label className="flex items-center space-x-2 text-white">
                <input
                  type="checkbox"
                  checked={showOnlyUnsplit}
                  onChange={(e) => setShowOnlyUnsplit(e.target.checked)}
                  className="form-checkbox h-4 w-4 text-blue-600 rounded bg-gray-700 border-gray-600"
                />
                <span>Show Unpaid Split Only</span>
              </label>
            </div>
            <div className="text-white">
              <span className="font-medium">Total Split Remainder: </span>
              <span className="text-green-400">
                ${(totalUnsplitAmount / 2).toFixed(2)}
              </span>
            </div>
          </div>

          {/* Input Form - Only visible to admin */}
          {isAdmin && (
            <>
              <div className="grid grid-cols-5 gap-4">
                <input
                  type="text"
                  value={newExpense.name}
                  onChange={(e) =>
                    setNewExpense({ ...newExpense, name: e.target.value })
                  }
                  placeholder="Purchase Name"
                  className="p-2 rounded bg-gray-800 text-white"
                />
                <input
                  type="date"
                  value={newExpense.date}
                  onChange={(e) =>
                    setNewExpense({ ...newExpense, date: e.target.value })
                  }
                  className="p-2 rounded bg-gray-800 text-white"
                />
                <input
                  type="number"
                  value={newExpense.cad}
                  onChange={(e) =>
                    setNewExpense({ ...newExpense, cad: e.target.value })
                  }
                  placeholder="CAD"
                  className="p-2 rounded bg-gray-800 text-white"
                />
                <input
                  type="number"
                  value={newExpense.usd}
                  onChange={(e) =>
                    setNewExpense({ ...newExpense, usd: e.target.value })
                  }
                  placeholder="USD (optional)"
                  className="p-2 rounded bg-gray-800 text-white"
                />
                <div className="flex items-center">
                  <label className="flex items-center space-x-2 text-white">
                    <input
                      type="checkbox"
                      checked={newExpense.split}
                      onChange={(e) =>
                        setNewExpense({
                          ...newExpense,
                          split: e.target.checked,
                        })
                      }
                      className="form-checkbox h-4 w-4 text-blue-600 rounded bg-gray-800 border-gray-600"
                    />
                    <span>Split Paid</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <button
                  onClick={handleAddExpense}
                  className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                >
                  Add Expense
                </button>
                {error && <p className="text-red-500">{error}</p>}
              </div>
            </>
          )}

          {/* Expenses Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-gray-800">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    Name {renderSortIcon("name")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("date")}
                  >
                    Date {renderSortIcon("date")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("cad")}
                  >
                    CAD {renderSortIcon("cad")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    USD
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Split Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Split Status
                  </th>
                  {isAdmin && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-gray-900 divide-y divide-gray-800">
                {sortedExpenses.length > 0 ? (
                  sortedExpenses
                    .filter((expense) => !showOnlyUnsplit || !expense.split)
                    .map((expense) => (
                      <tr key={expense.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {expense.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {formatDate(expense.date)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          ${parseFloat(expense.cad).toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {expense.usd
                            ? `$${parseFloat(expense.usd).toFixed(2)}`
                            : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          ${calculateSplit(expense.cad)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              expense.split
                                ? "bg-green-800 text-green-100"
                                : "bg-red-800 text-red-100"
                            }`}
                          >
                            {expense.split ? "Paid" : "Unpaid"}
                          </span>
                        </td>
                        {isAdmin && (
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button
                              onClick={() =>
                                handleEditClick(expense, "expense")
                              }
                              className="mr-2 px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white rounded"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() =>
                                handleToggleSplit(expense.id, expense.split)
                              }
                              className={`mr-2 px-3 py-1 rounded ${
                                expense.split
                                  ? "bg-yellow-600 hover:bg-yellow-700"
                                  : "bg-green-600 hover:bg-green-700"
                              } text-white`}
                            >
                              {expense.split ? "Mark Unpaid" : "Mark Paid"}
                            </button>
                            <button
                              onClick={() => handleRemoveExpense(expense.id)}
                              className="px-3 py-1 bg-red-600 hover:bg-red-700 text-white rounded"
                            >
                              Remove
                            </button>
                          </td>
                        )}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan={isAdmin ? 7 : 6}
                      className="px-6 py-4 text-center text-sm text-gray-400"
                    >
                      No expenses found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activeTab === "analytics" && (
        <div className="space-y-8">
          {/* Year Selector */}
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-white">
              Financial Analytics
            </h3>
            <div className="flex items-center space-x-2">
              <label className="text-gray-300">Select Year:</label>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                className="bg-gray-800 text-white p-2 rounded"
              >
                {availableYears.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Charts Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Monthly Earnings Chart */}
            <div className="bg-gray-800 p-4 rounded-lg">
              <h4 className="text-lg font-medium text-white mb-4">
                Monthly Split Earnings
              </h4>
              <div className="h-80">
                <Bar
                  data={{
                    labels: monthlyData.labels,
                    datasets: [
                      {
                        label: "Total Split Earnings (CAD)",
                        data: monthlyData.earnings,
                        backgroundColor: chartColors.earnings.backgroundColor,
                        borderColor: chartColors.earnings.borderColor,
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        grid: {
                          color: "rgba(255, 255, 255, 0.1)",
                        },
                        ticks: {
                          color: "rgba(255, 255, 255, 0.7)",
                          callback: function (value) {
                            return "$" + value;
                          },
                        },
                      },
                      x: {
                        grid: {
                          color: "rgba(255, 255, 255, 0.1)",
                        },
                        ticks: {
                          color: "rgba(255, 255, 255, 0.7)",
                        },
                      },
                    },
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            return (
                              "Total Split Earnings: $" +
                              context.parsed.y.toFixed(2)
                            );
                          },
                        },
                      },
                      legend: {
                        labels: {
                          color: "rgba(255, 255, 255, 0.7)",
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>

            {/* Monthly Expenses Chart (Split Amount) */}
            <div className="bg-gray-800 p-4 rounded-lg">
              <h4 className="text-lg font-medium text-white mb-4">
                Monthly Split Expenses
              </h4>
              <div className="h-80">
                <Bar
                  data={{
                    labels: monthlyData.labels,
                    datasets: [
                      {
                        label: "Total Split Expenses (CAD)",
                        data: monthlyData.splitExpenses,
                        backgroundColor:
                          chartColors.splitExpenses.backgroundColor,
                        borderColor: chartColors.splitExpenses.borderColor,
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        grid: {
                          color: "rgba(255, 255, 255, 0.1)",
                        },
                        ticks: {
                          color: "rgba(255, 255, 255, 0.7)",
                          callback: function (value) {
                            return "$" + value;
                          },
                        },
                      },
                      x: {
                        grid: {
                          color: "rgba(255, 255, 255, 0.1)",
                        },
                        ticks: {
                          color: "rgba(255, 255, 255, 0.7)",
                        },
                      },
                    },
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            return (
                              "Total Split Expenses: $" +
                              context.parsed.y.toFixed(2)
                            );
                          },
                        },
                      },
                      legend: {
                        labels: {
                          color: "rgba(255, 255, 255, 0.7)",
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>

            {/* Annual Summary */}
            <div className="bg-gray-800 p-4 rounded-lg lg:col-span-2">
              <h4 className="text-lg font-medium text-white mb-4">
                Annual Summary for {selectedYear}
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="bg-gray-700 p-4 rounded-lg">
                  <h5 className="text-gray-300 text-sm">
                    Total Split Earnings
                  </h5>
                  <p className="text-2xl font-bold text-green-400">
                    $
                    {monthlyData.earnings.reduce((a, b) => a + b, 0).toFixed(2)}
                  </p>
                </div>
                <div className="bg-gray-700 p-4 rounded-lg">
                  <h5 className="text-gray-300 text-sm">
                    Total Split Expenses
                  </h5>
                  <p className="text-2xl font-bold text-orange-400">
                    $
                    {monthlyData.splitExpenses
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2)}
                  </p>
                </div>
                <div className="bg-gray-700 p-4 rounded-lg">
                  <h5 className="text-gray-300 text-sm">Split Net Cashflow</h5>
                  <p
                    className={`text-2xl font-bold ${
                      monthlyData.cashflow.reduce((a, b) => a + b, 0) >= 0
                        ? "text-green-400"
                        : "text-red-400"
                    }`}
                  >
                    $
                    {monthlyData.cashflow.reduce((a, b) => a + b, 0).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CashflowManager;
