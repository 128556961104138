import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Predict = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [prediction, setPrediction] = useState("");
  const [timeInput, setTimeInput] = useState(
    startDate.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    })
  );

  const handleTimeChange = (e) => {
    const newTimeInput = e.target.value;
    setTimeInput(newTimeInput);

    try {
      const [hours, minutes] = newTimeInput.split(":");
      const newDate = new Date(startDate);
      newDate.setHours(parseInt(hours) || 0, parseInt(minutes) || 0, 0);
      setStartDate(newDate);
    } catch (error) {
      console.error("Error setting time:", error);
    }
  };

  const handleDateChange = (date) => {
    if (!date) return;

    try {
      const [hours, minutes] = timeInput.split(":");
      const newDate = new Date(date);
      newDate.setHours(parseInt(hours) || 0, parseInt(minutes) || 0, 0);
      setStartDate(newDate);
    } catch (error) {
      console.error("Error setting date:", error);
      setStartDate(date);
    }
  };

  const calculatePrediction = () => {
    const date100 = new Date(startDate.getTime() + 100 * 60 * 60 * 1000);
    const date120 = new Date(startDate.getTime() + 120 * 60 * 60 * 1000);

    const formatDate = (date) => {
      const dayName = date.toLocaleString("en-US", { weekday: "long" });
      const formattedDate = date.toLocaleString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      return `${dayName}, ${formattedDate}`;
    };

    setPrediction({
      start: formatDate(date100),
      end: formatDate(date120),
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-4">
        <div>
          <label className="text-sm font-medium mb-2 block">Select Date</label>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
          />
        </div>
        <div>
          <label className="text-sm font-medium mb-2 block">
            Enter Time (24h)
          </label>
          <input
            type="time"
            value={timeInput}
            onChange={handleTimeChange}
            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
          />
        </div>
      </div>

      <button
        onClick={calculatePrediction}
        className="w-full px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-md text-sm font-medium"
      >
        Calculate
      </button>

      {prediction && (
        <div className="p-4 bg-gray-800 rounded-md">
          <div className="flex flex-col gap-3">
            <div className="space-y-2">
              <div className="flex flex-col">
                <span className="text-xs text-gray-500">
                  Earliest Estimate:
                </span>
                <span className="text-md font-semibold text-blue-400">
                  {prediction.start}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-gray-500">Latest Estimate:</span>
                <span className="text-md font-semibold text-green-400">
                  {prediction.end}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Predict;
