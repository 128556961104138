import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

const VirtualList = ({ items, onRemove, onEdit }) => {
  const [visibleItems, setVisibleItems] = useState([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [editingId, setEditingId] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [copyButtonTexts, setCopyButtonTexts] = useState({});
  const containerRef = useRef(null);

  // Constants for virtualization
  const ITEM_HEIGHT = 48; // Height of each row in pixels
  const BUFFER_ITEMS = 5; // Number of items to render above/below visible area

  // Transform items to have id and value properties - using useMemo to prevent recreation on every render
  const transformedItems = useMemo(
    () => items.map(([id, value]) => ({ id, value })),
    [items]
  );

  const updateVisibleItems = useCallback(
    (currentScrollTop) => {
      if (!containerRef.current) return;

      const containerHeight = containerRef.current.clientHeight;

      // Calculate visible range
      const startIndex = Math.max(
        0,
        Math.floor(currentScrollTop / ITEM_HEIGHT) - BUFFER_ITEMS
      );
      const visibleCount =
        Math.ceil(containerHeight / ITEM_HEIGHT) + 2 * BUFFER_ITEMS;
      const endIndex = Math.min(
        startIndex + visibleCount,
        transformedItems.length
      );

      // Update visible items
      const newVisibleItems = transformedItems
        .slice(startIndex, endIndex)
        .map((item, index) => ({
          ...item,
          virtualIndex: startIndex + index,
        }));

      setVisibleItems(newVisibleItems);
    },
    [transformedItems, ITEM_HEIGHT, BUFFER_ITEMS]
  );

  // Effect for initial setup and when dependencies change
  useEffect(() => {
    if (containerRef.current) {
      updateVisibleItems(scrollTop);
    }
  }, [scrollTop, updateVisibleItems]);

  // Effect to update visible items when container is resized
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateVisibleItems(scrollTop);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [scrollTop, updateVisibleItems]);

  const handleScroll = (e) => {
    setScrollTop(e.target.scrollTop);
  };

  const handleStartEdit = (id, currentValue) => {
    setEditingId(id);
    setEditValue(currentValue);
  };

  const handleSaveEdit = (id) => {
    if (editValue.trim()) {
      onEdit(id, editValue.trim());
    }
    setEditingId(null);
    setEditValue("");
  };

  const handleKeyPress = (e, id) => {
    if (e.key === "Enter") {
      handleSaveEdit(id);
    } else if (e.key === "Escape") {
      setEditingId(null);
      setEditValue("");
    }
  };

  const handleCopyToClipboard = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        setCopyButtonTexts((prev) => ({
          ...prev,
          [id]: "Copied!",
        }));
        setTimeout(() => {
          setCopyButtonTexts((prev) => ({
            ...prev,
            [id]: "Copy",
          }));
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Calculate total height for the spacer div
  const totalHeight = transformedItems.length * ITEM_HEIGHT;

  return (
    <div className="relative overflow-hidden rounded-lg border border-gray-700 bg-gray-800">
      {/* Fixed header */}
      <div className="sticky top-0 z-10 bg-gray-700 w-full">
        <div className="grid grid-cols-12 px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
          <div className="col-span-3">ID</div>
          <div className="col-span-6">NAME</div>
          <div className="col-span-3 text-right">ACTIONS</div>
        </div>
      </div>

      {/* Scrollable content */}
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className="h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800"
        style={{ scrollbarWidth: "thin", scrollbarColor: "#4B5563 #1F2937" }}
      >
        <div
          style={{
            height: `${totalHeight}px`,
            position: "relative",
          }}
        >
          {visibleItems.map((item) => (
            <div
              key={item.id}
              className="absolute w-full grid grid-cols-12 px-4 py-3 border-b border-gray-700 hover:bg-gray-700"
              style={{
                height: `${ITEM_HEIGHT}px`,
                top: `${item.virtualIndex * ITEM_HEIGHT}px`,
              }}
            >
              <div className="col-span-3 flex items-center text-gray-300 truncate">
                {item.id}
              </div>
              <div className="col-span-6 flex items-center">
                {editingId === item.id ? (
                  <input
                    type="text"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    onBlur={() => handleSaveEdit(item.id)}
                    onKeyDown={(e) => handleKeyPress(e, item.id)}
                    className="w-full p-1 bg-gray-700 text-white border border-blue-500 rounded"
                    autoFocus
                  />
                ) : (
                  <span className="text-gray-300 truncate">{item.value}</span>
                )}
              </div>
              <div className="col-span-3 flex items-center justify-end space-x-2">
                <button
                  onClick={() => handleCopyToClipboard(item.id)}
                  className="px-3 py-1 bg-gray-600 hover:bg-gray-500 text-white text-xs rounded"
                >
                  {copyButtonTexts[item.id] || "Copy"}
                </button>
                <button
                  onClick={() => handleStartEdit(item.id, item.value)}
                  className="px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white text-xs rounded"
                >
                  Edit
                </button>
                <button
                  onClick={() => onRemove(item.id)}
                  className="px-3 py-1 bg-red-600 hover:bg-red-700 text-white text-xs rounded"
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer with item count */}
      <div className="bg-gray-700 px-4 py-2 text-sm text-gray-400">
        Showing {transformedItems.length}/108 items
      </div>
    </div>
  );
};

export default VirtualList;
