import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimeSince = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSince, setTimeSince] = useState("");
  const [timeInput, setTimeInput] = useState(
    selectedDate.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    })
  );

  const handleTimeChange = (e) => {
    const newTimeInput = e.target.value;
    setTimeInput(newTimeInput);

    try {
      const [hours, minutes] = newTimeInput.split(":");
      const newDate = new Date(selectedDate);
      newDate.setHours(parseInt(hours) || 0, parseInt(minutes) || 0, 0);
      setSelectedDate(newDate);
    } catch (error) {
      console.error("Error setting time:", error);
    }
  };

  const handleDateChange = (date) => {
    if (!date) return;

    try {
      const [hours, minutes] = timeInput.split(":");
      const newDate = new Date(date);
      newDate.setHours(parseInt(hours) || 0, parseInt(minutes) || 0, 0);
      setSelectedDate(newDate);
    } catch (error) {
      console.error("Error setting date:", error);
      setSelectedDate(date);
    }
  };

  const updateTimeSince = useCallback(() => {
    const now = new Date();
    const diff = now - selectedDate;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    setTimeSince(
      `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")} hours`
    );
  }, [selectedDate]);

  useEffect(() => {
    const timer = setInterval(updateTimeSince, 1000);
    return () => clearInterval(timer);
  }, [updateTimeSince]);

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-4">
        <div>
          <label className="text-sm font-medium mb-2 block">Select Date</label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
          />
        </div>
        <div>
          <label className="text-sm font-medium mb-2 block">
            Enter Time (24h)
          </label>
          <input
            type="time"
            value={timeInput}
            onChange={handleTimeChange}
            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
          />
        </div>
      </div>

      {timeSince && (
        <div className="p-3 bg-gray-800 rounded-md">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium">Time Since:</span>
            <span className="text-lg font-bold text-blue-400">{timeSince}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeSince;
