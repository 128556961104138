import React from "react";
import CashflowManager from "./CashflowManager";
import { useOutletContext } from "react-router-dom";

export function CashflowPage() {
  const { isAdmin } = useOutletContext();

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-200">Cashflow</h2>
        <p className="mt-2 text-sm text-gray-400">
          Track and manage your earnings, expenses, and overall cashflow
        </p>
      </div>
      <div className="bg-[#18181B] rounded-xl shadow-xl shadow-black/20 p-6">
        <CashflowManager isAdmin={isAdmin} />
      </div>
    </div>
  );
}
